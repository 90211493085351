import {Injectable, computed, signal} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable} from 'rxjs';

import {catchError, map, tap} from 'rxjs/operators';

import {environment} from '@env/environment';

import {FormFactory} from '@core/modules/forms/services/form.factory';

import {IToken} from '../types/token.model';
import {AuthLogin} from '../types/auth-login';
import {RegisterInfo} from '../types/register.model';
import {APIResponse} from '@core/helpers/types/api-response';
import {ApiKey} from '../types/api-key';
import {UserView} from '@shared/modules/users/types/user-view.model';

import {User, UserRole} from '@shared/modules/users/types/user';

import {UserStore} from './stores/user.store';
import {TokenStore} from './stores/token.store';
import {AccountStore} from '@core/modules/auth/services/stores/account.store';
import {AccountPreview, WhoAmIDto} from '@core/modules/auth/types/whoami';
import {CookieStore} from '@core/modules/storage';

/**
 * Provides all Http methods related to User Authentication
 */
@Injectable({providedIn: 'root'})
export class AuthService {
	readonly currentUser = signal<UserView | undefined>(
		new UserView(this.userStore.getUser())
	);
	readonly isLoggedIn = computed(() => !!this.currentUser()?.uuid);

	constructor(
		private router: Router,
		private http: HttpClient,
		private tokenStore: TokenStore,
		private userStore: UserStore,
		private cookie: CookieStore,
		private accountsStore: AccountStore,
		private forms: FormFactory
	) {}

	/**
	 * Tries to register the user with given information:
	 * - if the request is successful, the user must confirm his email, so no actions follow.
	 * - If an error occurred, the LoginComponent will take car of displaying the error messages.
	 */
	signUp = (info: RegisterInfo): Observable<void> =>
		this.http.post<void>(environment.root_url + '/register', info).pipe(
			tap(
				() => this.forms.handleSuccessMessages('HTTP_SUCCESS.REGISTER'),
				(err: any) => this.forms.handleErrorMessages(err)
			)
		);

	/**
	 * Tries to authenticate the user with given credentials:
	 * - if the request is successful, the session start and user is redirect to his dashboard
	 * - If an error occurred, the LoginComponent will take car of displaying the error messages.
	 */
	logIn = (
		form: AuthLogin
	): Observable<IToken> => // Log and update current User Subject
		this.http
			.post<IToken>(environment.root_url + '/login', form)
			.pipe(
				catchError(async err => {
					this.forms.handleErrorMessages(err);
					return err;
				})
			)
			.pipe(
				tap(
					token => {
						if (token.code === 200) {
							this.tokenStore.saveToken(token);
							this.whoami()
								.pipe(
									tap(res => {
										if (res) {
											this.accountsStore.saveAccounts(res.accounts);
											this.currentUser.set(
												new UserView(this.userStore.saveUser(res.user))
											);
										}
									})
								)
								.pipe(
									tap(() => this.cookie.set('CutzMessage', 'true', 24 * 60 * 60 * 1000)),
									map(() =>
										this.router
											.navigate(['/app/dashboard'])
											.catch(err => console.error('Failed to redirect', err))
									)
								)
								.subscribe();
						}
					},
					err => console.error(err)
				)
			);

	/**
	 * Clears user's session and redirect to homepage
	 */
	logOut = (): void => {
		this.tokenStore.clearToken();
		this.userStore.clearUser();
		this.cookie.deleteAll();
		this.accountsStore.clearAccounts();
		this.currentUser.set(undefined);
		this.router.navigate(['/']);
	};

	/**
	 * Send request to reset password of user depending on given user
	 * @param email user's public identifier
	 */
	resetPassword = (email: string): Observable<any> =>
		this.http
			.post<any>(environment.root_url + '/users/forgotten-password', {email})
			.pipe(
				map(() =>
					this.router
						.navigate(['/'])
						.catch(err => console.error('Failed to redirect', err))
				)
			);

	/**
	 *
	 * @param form
	 * @param token
	 * @returns
	 */
	updatePassword = (
		form: {password: string; _password: string},
		token: string
	): Observable<any> =>
		this.http.put<void>(`${environment.root_url}/users/change-password`, form, {
			headers: {Authorization: 'Bearer ' + token},
		});

	/**
	 * Retrieves current user information based on stored token
	 */
	whoami = (): Observable<WhoAmIDto> =>
		this.http
			.get<APIResponse<WhoAmIDto>>(environment.root_url + '/me')
			.pipe(map(res => res.data));

	/**
	 * Determines if current user has given Role
	 */
	hasRole(role: UserRole | string, user?: UserView): boolean {
		const currentRoles = user ? user.roles : this.currentUser()?.roles;
		return !currentRoles
			? false
			: currentRoles.filter(
					(currentRole: string) => currentRole === role.toString()
			  ).length !== 0;
	}

	getApiKey = (account_uuid?: string): Observable<string> =>
		this.http
			.patch<APIResponse<ApiKey>>(
				environment.root_url + '/accounts/' + account_uuid + '/api-key',
				{}
			)
			.pipe(map(res => res.data.apiKey));

	updateCurrentAccount(updatedAccount: Partial<AccountPreview>) {
		const user = this.currentUser();
		if (user) {
			const previousAccount = this.currentUser()?.account?.uuid;
			let account = user.account;
			user.account = {...account, ...updatedAccount};
			this.currentUser.set(new UserView(this.userStore.saveUser(user)));
			if (!previousAccount || previousAccount !== updatedAccount.uuid) {
				this.cookie.delete('videos-library');
				location.reload();
			}
		}
	}

	updateCurrentUserInformation(input: User | UserView) {
		const user = {...this.currentUser(), ...input};
		if (user?.uuid) {
			this.currentUser.set(new UserView(this.userStore.saveUser(user)));
		}
	}

	isAdminType = () =>
		this.hasRole(UserRole.DEV, this.currentUser()) ||
		this.hasRole(UserRole.VIDMIZER, this.currentUser());

	isUserType = () => this.hasRole(UserRole.USER, this.currentUser());

	isPlayerActive = () =>
		this.currentUser()?.account?.accountRole?.rights.some(
			obj => obj.name === 'player'
		);
}
